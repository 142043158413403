<template>
  <div class="pack-and-dispatch-page">
    <navbar/>
    <div class="container">
      <search-field
        :delay="500"
        :placeholder="$t('packAndDispatch.searchPlaceholder')"
        @search="onSearchInput"/>
      <div class="tabs">
        <ul>
          <li
            v-for="tab in tabs"
            :key="`page_tabs_${tab}`"
            :class="{'is-active': tab === selectedTab }">
            <a @click="selectTab(tab)">
              {{ $t(`packAndDispatch.tabs.${tab}`) }}
            </a>
          </li>
        </ul>
      </div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th
              v-for="header in [
                $t('packAndDispatch.table.headers.courier'),
                $t('packAndDispatch.table.headers.orders'),
                $t('packAndDispatch.table.headers.packer'),
                $t('packAndDispatch.table.headers.leaveBy'),
                $t('packAndDispatch.table.headers.packedAt'),
                $t('packAndDispatch.table.headers.startedAt'),
                $t('packAndDispatch.table.headers.notes'),
                $t('packAndDispatch.table.headers.status')
              ]"
              :key="`page_table_header_${header}`">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="tripsToDisplay.length === 0">
            <td
              colspan="99"
              class="has-text-centered">
              {{ $t('packAndDispatch.table.empty') }}
            </td>
          </tr>
          <dispatch-trip-row
            v-for="trip in tripsToDisplay"
            :key="`trip_${trip.id}`"
            :trip="trip"
            @show-trip-tasks="showTripTasks"
            @update-packer="updateTripPackerName"
            @mark-packed="markAsPacked"
            @mark-started="markAsStarted"
            @cancel-packed="cancelPacked"
            @cancel-started="cancelStarted"
            @update-notes="updateTripNotes"/>
        </tbody>
      </table>
    </div>
    <b-modal
      :active.sync="tripTasksModalActive"
      :width="400"
      animation>
      <dispatch-trip-tasks :trip="selectedTrip"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apiWithFiltersMixin from '@/mixins/api-with-filters-mixin'
import { formatDate } from '@js/utils'
import fulfilmentChannelMixin from '@/mixins/fulfilment-channel-mixin'

const Navbar = () => import('@components/Navbar')
const DispatchTripRow = () => import('@components/DispatchTripRow')
const DispatchTripTasks = () => import('@components/DispatchTripTasks')
const SearchField = () => import('@components/SearchField')

const TABS = {
  UPCOMING: 'upcoming',
  DISPATCHED: 'dispatched'
}

export default {
  name: 'pack-and-dispatch-page',
  components: {
    Navbar,
    DispatchTripRow,
    DispatchTripTasks,
    SearchField
  },
  mixins: [
    apiWithFiltersMixin,
    fulfilmentChannelMixin
  ],
  data() {
    return {
      UPCOMING: TABS.UPCOMING,
      DISPATCHED: TABS.DISPATCHED,
      searchTerm: '',
      tabs: [
        TABS.UPCOMING,
        TABS.DISPATCHED
      ],
      selectedTab: TABS.UPCOMING,
      selectedTrip: null,
      tripTasksModalActive: false,
      isInitialTripsLoaded: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading',
      'getErrorMessage'
    ]),
    ...mapGetters('trip', [
      'trips',
      'dispatchedTrips'
    ]),
    loadingOverlayVisible() {
      // only show loading overlay on first load
      return !this.isInitialTripsLoaded && this.tripsLoading
    },
    tripsLoading() {
      return this.getLoading('trip/getTrips')
    },
    updateTripError() {
      return this.getErrorMessage('trip/updateTripPacked') ||
        this.getErrorMessage('trip/updateTripStarted')
    },
    tripsToDisplay() {
      const trips = this.selectedTab === TABS.UPCOMING ? this.trips : this.dispatchedTrips
      if (this.searchTerm) {
        const search = RegExp(this.searchTerm, 'i')
        return trips.filter(trip => {
          if (trip.fulfilmentUser && search.test(trip.fulfilmentUser.name)) {
            return true
          }
          return trip.tasks.some(task => search.test(task.orderIdentifier))
        })
      }
      return trips
    }
  },
  created() {
    this.resetTripStore()

    this.watchLoadingOnce('tripsLoading', 'isInitialTripsLoaded')

    this.watchErrorNotification('updateTripError')
  },
  methods: {
    ...mapActions('trip', [
      'resetTripStore',
      'getTripsRequest',
      'updateTripPackerNameRequest',
      'updateTripPackedRequest',
      'updateTripStartedRequest',
      'updateTripNotesRequest'
    ]),
    // override method in mixin
    onReceived(data) {
      if (data.object === 'Trip') {
        // TODO: check if will disrupt any user actions
        this.debounceRequest(() => {
          this.getTrips()
          this.getTrips({ dispatched: true })
        })
      }
    },
    // override method in mixin
    apiRequest() {
      this.subscribeFulfilmentChannel()

      this.getTrips()
      this.getTrips({ dispatched: true })
    },
    getTrips(options = { dispatched: false }) {
      this.getTripsRequest({
        servingDate: formatDate(this.selectedDate),
        timeRangeStart: this.selectedTimeRange[0],
        timeRangeEnd: this.selectedTimeRange[1],
        hubIds: this.selectedHubIds,
        dispatched: options.dispatched
      })
    },
    onSearchInput(searchTerm) {
      this.searchTerm = searchTerm
    },
    selectTab(tab) {
      this.selectedTab = tab
    },
    showTripTasks(trip) {
      this.selectedTrip = trip
      this.tripTasksModalActive = true
    },
    updateTripPackerName(tripId, packerName) {
      this.updateTripPackerNameRequest({ tripId, packerName })
    },
    markAsPacked(tripId) {
      this.updateTripPackedRequest({ tripId, pack: true })
    },
    markAsStarted(tripId) {
      this.updateTripStartedRequest({ tripId, start: true })
    },
    deleteConfirmation({ message, onConfirm }) {
      this.$buefy.dialog.confirm({
        message: message || 'Are you sure?',
        confirmText: 'Remove',
        type: 'is-danger',
        onConfirm,
        animation: null
      })
    },
    cancelPacked(tripId) {
      this.deleteConfirmation({
        onConfirm: () => this.updateTripPackedRequest({ tripId, pack: false })
      })
    },
    cancelStarted(tripId) {
      this.deleteConfirmation({
        onConfirm: () => this.updateTripStartedRequest({ tripId, start: false })
      })
    },
    updateTripNotes(tripId, notes) {
      this.updateTripNotesRequest({ tripId, notes })
    }
  }
}
</script>

<style lang="scss" scoped>
.pack-and-dispatch-page {
  .container {
    margin-top: $space-l;

    @include touch {
      padding-right: $space-m;
      padding-left: $space-m;
    }

    .search-field {
      position: absolute;
      top: 0;
      right: 0;
    }

    .tabs {
      a {
        border-bottom-width: 4px;
      }

      li:not(.is-active) {
        a:not(:hover) {
          border-color: transparent;
        }
      }
    }

    table {
      &::v-deep {
        th {
          white-space: nowrap;
        }

        th,
        td {
          vertical-align: middle;
          @extend %body;
        }
      }
    }
  }
}
</style>
