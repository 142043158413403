var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pack-and-dispatch-page"},[_c('navbar'),_c('div',{staticClass:"container"},[_c('search-field',{attrs:{"delay":500,"placeholder":_vm.$t('packAndDispatch.searchPlaceholder')},on:{"search":_vm.onSearchInput}}),_c('div',{staticClass:"tabs"},[_c('ul',_vm._l((_vm.tabs),function(tab){return _c('li',{key:("page_tabs_" + tab),class:{'is-active': tab === _vm.selectedTab }},[_c('a',{on:{"click":function($event){return _vm.selectTab(tab)}}},[_vm._v(" "+_vm._s(_vm.$t(("packAndDispatch.tabs." + tab)))+" ")])])}),0)]),_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',_vm._l(([
              _vm.$t('packAndDispatch.table.headers.courier'),
              _vm.$t('packAndDispatch.table.headers.orders'),
              _vm.$t('packAndDispatch.table.headers.packer'),
              _vm.$t('packAndDispatch.table.headers.leaveBy'),
              _vm.$t('packAndDispatch.table.headers.packedAt'),
              _vm.$t('packAndDispatch.table.headers.startedAt'),
              _vm.$t('packAndDispatch.table.headers.notes'),
              _vm.$t('packAndDispatch.table.headers.status')
            ]),function(header){return _c('th',{key:("page_table_header_" + header)},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',[(_vm.tripsToDisplay.length === 0)?_c('tr',[_c('td',{staticClass:"has-text-centered",attrs:{"colspan":"99"}},[_vm._v(" "+_vm._s(_vm.$t('packAndDispatch.table.empty'))+" ")])]):_vm._e(),_vm._l((_vm.tripsToDisplay),function(trip){return _c('dispatch-trip-row',{key:("trip_" + (trip.id)),attrs:{"trip":trip},on:{"show-trip-tasks":_vm.showTripTasks,"update-packer":_vm.updateTripPackerName,"mark-packed":_vm.markAsPacked,"mark-started":_vm.markAsStarted,"cancel-packed":_vm.cancelPacked,"cancel-started":_vm.cancelStarted,"update-notes":_vm.updateTripNotes}})})],2)])],1),_c('b-modal',{attrs:{"active":_vm.tripTasksModalActive,"width":400,"animation":""},on:{"update:active":function($event){_vm.tripTasksModalActive=$event}}},[_c('dispatch-trip-tasks',{attrs:{"trip":_vm.selectedTrip}})],1),_c('b-loading',{attrs:{"active":_vm.loadingOverlayVisible}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }